<template>
<div class="card p-3 bg-accent">
    <div class="d-flex-inline">
        <span class="span">
            <span class="h5 text-bold text-title mb-3">{{f_metric}}</span>

        </span>

        <a href="#jf" class="h5n text-bold float-right link d-none">View Report</a>
        <div class="d-flex-inline float-right text-muted">
            <small class="text-success" v-if="comparison_indicator.direction=='up'">
                <ph-arrow-up-left :size="18" /> {{comparison_indicator.percentage}}%</small>
            <small class="text-danger" v-if="comparison_indicator.direction=='down'">
                <ph-arrow-down-left :size="18" /> {{comparison_indicator.percentage}}%</small>

        </div>
    </div>
    <div class="d-flex-inline mt-2b">
        <span class="span">
            <span class="h3 text-bold text-title text-muted">{{y_prefix}}{{(Math.round(headline * 100) / 100)}}</span>
            <br>

        </span>

    </div>
    <hr />
    <div class="d-flex-inline mt-2 mb-4">
        <span class="span">
            <span class="h6 text-bold text-title text-muted">{{title}}</span>
        </span>
    </div>
    <div :class="rand_class"></div>

    <div class="row">
        <div class="col-lg-4">
            <select class="form-control form-control-sm d-none" id="exampleFormControlSelect1">
                <option>last week</option>
                <option>last 30 days</option>
                <option>last 60 days</option>
                <option>last 90 days</option>
                <option>last year</option>
                <option>all </option>
            </select>
        </div>
        <div class="col-lg-8 float-right">
            <div class="btn-group btn-group-sm  mr-2 d-inline-flex float-right" role="group" aria-label="First group">
                <button type="button" class="btn btn-slim btn-secondary" :class="{'btn-primary':over=='DAY'}" @click="set_over('DAY')">D</button>
                <button type="button" class="btn btn-slim btn-secondary" :class="{'btn-primary':over=='WEEK'}" @click="set_over('WEEK')">W</button>
                <button type="button" class="btn btn-slim btn-secondary" :class="{'btn-primary':over=='MONTH'}" @click="set_over('MONTH')">M</button>
                <button type="button" class="btn btn-slim btn-secondary" :class="{'btn-primary':over=='YEAR'}" @click="set_over('YEAR')">Y</button>

            </div>
        </div>
    </div>

</div>
</template>

<style>
@import "../assets/css/frappe.css";
</style>

<script>
import {
    PhArrowUpLeft,
    PhArrowDownLeft,
    PhCalendarBlank,
} from "phosphor-vue";

import {
    Chart
} from "frappe-charts/dist/frappe-charts.esm.js";
var jsonata = require("jsonata");
import {
    v4 as uuidv4
} from 'uuid';
import _ from "lodash";
export default {
    name: "Chart",
    props: ["query", "metric", "title", "y_prefix"],
    components: {
        PhArrowUpLeft,
        PhArrowDownLeft,
        PhCalendarBlank
    },
    data() {
        return {
            chart: null,
            headline: 0,
            over: "MONTH",
            type: "axis-mixed", // or 'bar', 'line', 'pie', 'percentage'
            height: 250,
            colors: ["#7cd6fd", "#743ee2", "#ff5252", "#ffc409", "#ff5e57"],
            rand_class: "chart_" + uuidv4(),

            f_metric: "",

            comparison_indicator: {
                "direction": "side",
                "value": "10.00",
                "percentage": "10.00"

            },

        };
    },
    mounted() {
        //this.rand_class = "chart" + uuidv4();

        this.get_data()
    },
    methods: {
        set_over(over) {
            this.over = over;
            this.get_data(over)
        },
        get_data(over) {
            if (over) {
                this.query["over"] = over
            }
            
            var baseURI = this.$baseurl + "/analytics";
            var config = {
                Authorization: "Bearer " + this.$store.getters.getUser.token,
            };
            this.$http
                .post(baseURI, this.query, {
                    headers: config,
                })
                .then((result) => {
                    if (result.data.message === "invalid token") {} else {}

                    if (result.data) {
                        this.f_metric = _.startCase(this.metric);
                        this.over = result.data.report.over;
                        let monthly_earnings_data = {
                            labels: jsonata("[period]").evaluate(result.data.data),
                            datasets: [{
                                    name: _.startCase(this.metric),
                                    values: jsonata("[" + this.metric + "]").evaluate(result.data.data).map((x) => {
                                        if (x != null) {
                                            return Math.round(x * 100) / 100
                                        } else {
                                            return 0
                                        }

                                    }),
                                    chartType: 'bar'
                                },
                                {
                                    name: "(Previous Period)",
                                    values: jsonata("[" + this.metric + "]").evaluate(result.data.comparison_data).map((x) => {
                                        if (x != null) {

                                            return Math.round(x * 100) / 100

                                        } else {
                                            return 0
                                        }
                                    }),
                                    chartType: 'line'
                                },
                            ],
                        };
                        this.headline = result.data.summary[0][this.metric]
                        let diff = (result.data.summary[0][this.metric] || 0) - (result.data.comparison[0][this.metric] || 0)
                        let diff_percent = Math.round(((diff / (result.data.comparison[0][this.metric] )|| 1) * 100)* 100) / 100 ||""
                        if(!result.data.comparison[0][this.metric] &&  diff > 0){
                            diff_percent = 0
                        }
                        this.comparison_indicator = {
                            "direction": diff > 0 ? "up" : "down",
                            "value": diff,
                            "percentage": diff_percent
                        }
                        const chart = new Chart("." + this.rand_class, {
                            // or a DOM element,
                            // new Chart() in case of ES6 module with above usage

                            data: monthly_earnings_data,
                            type: "axis-mixed", // or 'bar', 'line', 'scatter', 'pie', 'percentage'
                            height: 280,
                            colors: ["#5469d4", "#212123"],
                            lineOptions: {
                                hideDots: 1,
                                spline: 0,
                                regionFill: 0,
                                hideLine: 0,
                                dotSize: 1
                            },
                            barOptions: {
                                spaceRatio: 0.28, // default: 1
                            },
                            axisOptions: {
                                xAxisMode: "tick",
                                xIsSeries: 1,
                            },
                            tooltipOptions: {
                                formatTooltipX: (d) => (d + "").toUpperCase(),
                                formatTooltipY: (d) => this.y_prefix + (d || 0),
                            },
                        });
                    }
                });

        }
    },
};
</script>
