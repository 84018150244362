<template>
<div class="container-fluid pb-5">
    <div class="row">
        <div class="col-12 pl-2 pr-4 pt-4 bg-accent">
            <div class="row px-2">
                <div class="col-lg-8">
                    <div class="toolbar mt-2">
                        <div class="btn-groupn" role="group" aria-label="Basic example">
                            <h3 class="vv" v-if="!$route.params.id">Upload Beat</h3>
                            <h3 class="vv text-capitalize" v-if="$route.params.id"> {{ Name }}</h3>
                        </div>
                    </div>
                </div>
                <div class="col text-right">
                    <button type="button" class="btn btn-mid btn-primary d-inline" @click="verify()" :disabled="!ready">
                        <span class="align-middle" v-if="$route.params.id">Save Changes</span>
                        <span class="align-middle" v-else>Upload</span>
                        <span v-show="saving" class="loading pl-1 my-auto pt-1 pull-right text-right"></span>
                    </button>
                </div>
            </div>
        </div>
        <template class="ff">
            <!--div class="sp sp-circle" v-show="!ready"></div-->
            <div class="loading-lgh" v-show="!ready"></div>

            <div class="col-12 bg-accent">
                <ul class="errors px-0 text-left" v-for="error in validationErrors" :key="error.message">
                    <li class="d-block text-danger px-0 note note-danger" v-if="validationErrors[0] != {}" data-aos="fade-in">
                        <ph-warning class="align-middle" />
                        <span class=""></span>
                        <span class="ml-2 fnt-14 ls-default">{{ error.message }}</span>
                    </li>
                </ul>
            </div>

            <div class="col-12 bg-accent border-bottom">
                <ul class="nav nav-tabs px-0 mx-0 border-0" id="myTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab">Basic Info</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="meta-tab" data-toggle="tab" href="#meta" role="tab">Metadata</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="contact-tab" data-toggle="tab" href="#options" role="tab">Options</a>
                    </li>
                    <li class="nav-item ">
                        <a class="nav-link" id="splits-tab" data-toggle="tab" href="#splits" role="tab">Collaborators</a>
                    </li>

                    <li class="nav-item ">
                        <a class="nav-link" id="analytics-tab" data-toggle="tab" href="#analytics" role="tab" v-if="$route.params.id">Analytics</a>
                    </li>
                    <li class="nav-item ">
                        <a class="nav-link" id="splits-tab" data-toggle="tab" href="#splits" role="tab" v-if="$route.params.id">Sales</a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-12 text-center pt-5 animate__animated animate__fadeIn" v-show="!ready">
                <span class="loading-lg  text-center mx-auto"></span>
                <br>
                <br>
                <span class="text-muted pt-5 mt-5">loading...</span>
            </div>
            <div class="col-12 pb-5" v-if="ready">
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active px-2 pt-4" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="row">
                                    <div class="col-12">
                                        <label>Beat Art Png/Jpg</label>

                                        <div class="
                          file-card
                          mt-2
                          p-4b
                          align-items-center align-middle
                          justify-content-center
                          text-center
                        " :class="{ active: dimage.original_name }" @click="pick_filei()" style="
                          width: 200px !important;
                          height: 200px !important;
                        ">
                                            <progressive-img :blur="0" class="radius align-middle" v-if="dimage.img_url_300" style="
                            height: 198px !important;
                            width: 198px !important;
                          " :src="dimage.img_url" :placeholder="dimage.img_url_300" />
                                            <!--img height="200px" width="200px" v-if="dimage.ImgURL300" class="text-center rounded justify-content-center" :src="dimage.ImgURL300"-->

                                            <span style="position: relative; top: 40%" class="fname text-primary text-muted mx-auto my-auto" v-if="!dimage.img_url_300">
                                                <ph-plus-circle :size="48" />
                                            </span>
                                        </div>
                                        <button class="btn btn-secondary mt-2" v-show="Image != ''" @click="remove_image()">
                                            Remove Image
                                        </button>
                                    </div>
                                    <div class="col-12 mt-4">
                                        <label class="gg">Untagged Wav/Mp3</label>

                                        <div class="file-card mt-2 py-3 px-4" :class="{ active: dwav.original_name }" @click="pick_filew()">
                                            <label class="
                            fname
                            text-muted
                            mt-2
                            align-middle
                            d-flex
                            cursor-pointer
                          " v-if="dwav.original_name">
                                                <ph-music-note-simple class="text-primary align-middle mr-2" :size="20" />
                                                <span class="tt">{{
                            dwav.original_name
                          }}</span></label>
                                            <label class="
                            fname
                            text-muted
                            px-2
                            mt-2
                            align-middle
                            d-flex
                            cursor-pointer
                          " v-if="!dwav.original_name">
                                                <span class="tt">select wav or mp3 file</span>
                                            </label>
                                        </div>

                                        <small class="text-muted">mp3 file will be automatically generated</small>
                                        <button class="btn btn-secondary mt-2" v-show="Wav != ''" @click="remove_wav()">
                                            Remove File
                                        </button>
                                        <br v-show="Wav == ''" />
                                    </div>
                                    <div class="col-12 mt-4">
                                        <label>Track Stems Zip/Rar</label>
                                        <div class="file-card mt-2 py-3 px-4" :class="{ active: dzip.original_name }" @click="pick_filez()">
                                            <label class="
                            fname
                            text-muted
                            mt-2
                            align-middle
                            d-flex
                            cursor-pointer
                          " v-if="dzip.original_name">
                                                <ph-archive class="text-primary align-middle mr-2" :size="20" />

                                                <span class="tt">{{
                            dzip.original_name
                          }}</span></label>
                                            <label class="
                            fname
                            text-muted
                            px-2
                            mt-2
                            align-middle
                            d-flex
                            cursor-pointer
                          " v-if="!dzip.original_name">
                                                <span class="tt">select zip or rar file</span>
                                            </label>
                                        </div>
                                        <button class="btn btn-secondary mt-2" v-show="Stems != ''" @click="remove_zip()">
                                            Remove File
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8">
                                <form class="pt-3 pt-lg-0">
                                    <div class="form-group">
                                        <label> Beat name</label>
                                        <input type="text" v-model="Name" class="form-control" id="beatname" />
                                    </div>
                                    <div class="form-group">
                                        <label>Genre</label>
                                        <select v-model="Genre" class="form-control" id="genre">
                                            <option v-for="x in Object.keys(genres)" :key="x">
                                                {{ x }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label>Subgenre</label>
                                        <select v-model="SubGenre" class="form-control" id="subgenre">
                                            <option v-for="x in genres[Genre]" :key="x">
                                                {{ x }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group">
                                        <label>Tags</label>
                                        <vue-tags-input v-model="tag" :tags="Tags" @tags-changed="(newTags) => (Tags = newTags)" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade px-2 pt-4" id="meta" role="tabpanel">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="row">
                                    <div class="form-group col-12">
                                        <label>BPM</label>
                                        <input v-model="BPM" type="number" minlength="10" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="1000" class="form-control" id="beatname" />
                                    </div>

                                    <div class="form-group col-12">
                                        <label>Description</label>

                                        <textarea maxlength="250" v-model="Description" class="form-control" id="description" rows="2"></textarea>
                                    </div>
                                    <div class="form-group col-12">
                                        <label for="exampleFormControlSelect1">Type</label>
                                        <select class="form-control" v-model="Type" id="type">
                                            <option>Beat</option>
                                            <option>With Hook</option>
                                            <option>Sample</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-lg-6">
                                        <label>Key</label>
                                        <select v-model="skey" class="form-control" id="skey">
                                            <template v-for="x in keys">
                                                <option v-for="y in degree" :key="y + x">
                                                    {{ x + y }}
                                                </option>
                                            </template>
                                        </select>
                                    </div>

                                    <div class="form-group col-lg-6">
                                        <label>Scale</label>

                                        <select v-model="scale" class="form-control" id="mood">
                                            <option v-for="x in scales" :key="x">
                                                {{ x }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="form-group col-12">
                                        <label>Mood</label>

                                        <select v-model="Mood" class="form-control" id="mood">
                                            <option v-for="x in moods" :key="x">
                                                {{ x }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade px-2 pt-4" id="options" role="tabpanel">
                        <div class="row">
                            <div class="col-lg-8">
                                <div class="row">
                                    <div class="form-group col-12">
                                        <label>Release Date & Time</label>

                                        <datetime type="datetime" v-model="ReleaseDate"></datetime>
                                    </div>

                                    <div class="form-group col-12">
                                        <label for="exampleFormControlSelect1">Free Download</label>
                                        <select class="form-control" v-model="FreeDownload" id="freedownload">
                                            <option>None</option>
                                            <option>Mp3</option>
                                            <option>Wav</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-12">
                                        <label>Draft</label>

                                        <div class="switch_box box_1n">
                                            <input type="checkbox" class="switch_1" v-model="Draft" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade px-2 pt-4" id="splits" role="tabpanel">
                        <div class="row">
                            <div class="col-lg-8 col-sm-12">
                                <div class="row">
                                    <div class="form-group col-lg-3 col-sm-12">
                                        <label>Name</label>
                                        <input type="text" class="form-control" />
                                    </div>
                                    <div class="form-group col-lg-6 col-sm-12">
                                        <label>Paypal Email Address</label>
                                        <input type="text" class="form-control" />
                                    </div>

                                    <div class="form-group col-lg-3 col-sm-12">
                                        <label>Split (%)</label>
                                        <input type="number" class="form-control" />
                                    </div>
                                    <div class="col-12">
                                        <hr />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <button class="btn btn-secondary">Add Split</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade px-2 pt-4" id="analytics" role="tabpanel">
                        <div class="row">
                            <div class="col-6 pt-4">
                                <report :title="'Total Views on '+$route.params.id" :query="total_views_per_period" metric="total_views"></report>
                            </div>
                            <div class="col-6 pt-4">
                                <report :title="'Total People Viewing '+$route.params.id" :query="total_people_per_period" metric="total_people"></report>
                            </div>
                            <div class="col-12 pt-5">
                                <report :title="'Total Sales on '+$route.params.id" :query="total_amount_per_period" metric="total_amount" y_prefix="$"></report>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>

    <div class="modal fade" id="file_modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header text-center">
                    <h5 class="modal-title text-center" id="exampleModalLabel">
                        Upload File
                    </h5>
                    <span class="btn-close" data-dismiss="modal" aria-label="Close">
                        <ph-x />
                    </span>
                </div>
                <div class="modal-body">
                    <ul class="nav nav-tabs pt-0 mt-0 mb-3" id="pills-tab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-files">My Files</a>
                        </li>
                        <li class="nav-item" role="presentation">
                            <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-upload">New Upload</a>
                        </li>
                    </ul>
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active file-cont-sel" id="pills-files" role="tabpanel" aria-labelledby="pills-home-tab">
                            <ul class="list-group list-group-flush">
                                <li class="list-group-item mb-0 py-2 f-select" :class="{ factive: x.id == fid }" v-for="x in filesf.sort((a, b) => a.original_name.localeCompare(b.original_name))" :key="x.id" @click="selectf(x)">
                                    <img v-if="x.img_url_300!=''" class="radius" :src="x.img_url_300" alt="" srcset="" height="38px" width="38px"> <span class="mx-2"> {{ truncate(x.original_name, 45) }}</span>
                                </li>
                            </ul>
                            <div class="justify-content-center text-center" v-if="!filesf[0]">
                                <p>
                                    you don't have existing (
                                    <span v-for="(e, i) in ext" :key="e">{{ e }} <span v-if="i < ext.length - 1">, </span> </span>) files
                                </p>
                                <button class="btn btn-primary text-center mx-auto mt-4" @click="switcht()">
                                    Upload File
                                </button>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-upload" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <dashboard class="dash-b" :uppy="uppy" :plugins="['ImageEditor']" />
                        </div>
                    </div>
                </div>

                <!--div class="modal-footer pb-0 px-0 mx-0 ">
                                <div class="col-6 d-table btn-modal-left px-0 mx-0 my-0 " data-dismiss="modal">
                                    <span class="align-middle d-table-cell text-center">CANCEL</span>
                                </div>
                                <div class="col-6 d-table btn-modal-right mx-0 px-0 my-0 ">
                                    <span class="align-middle d-table-cell text-center"> <i class="ci-user"></i> SUBMIT</span>
                                </div>
                            </div-->
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapMutations
} from "vuex";
import Snackbar from "node-snackbar";
import "node-snackbar/dist/snackbar.css";
import genres from "@/assets/json/genres.json";
import VueTagsInput from "@johmun/vue-tags-input";
import "@uppy/image-editor/dist/style.min.css";
//import FilePondPluginImagePreview from "filepond-plugin-image-preview";
var $ = require("jquery");
import {
    Dashboard
} from "@uppy/vue";
import {
    Datetime
} from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

import Uppy from "@uppy/core";

import XHRUpload from "@uppy/xhr-upload";
const ImageEditor = require("@uppy/image-editor");
import Report from "../components/Report.vue";
import {
    PhWarning,
    PhPlusCircle,
    PhMusicNoteSimple,
    PhArchive,
    PhX,
} from "phosphor-vue";

export default {
    name: "newbeat",
    components: {
        //FilePond: vueFilePond(FilePondPluginImagePreview),
        Dashboard,
        VueTagsInput,
        Datetime,
        PhWarning,
        PhPlusCircle,
        PhMusicNoteSimple,
        PhArchive,
        PhX,
        Report
    },
    data() {
        return {
            dbeat: {},
            Name: "",
            Description: "",
            genres: genres,
            validationErrors: [],
            UserId: "",
            dimage: {},
            dwav: {},
            dzip: {},
            keys: ["A", "B", "C", "D", "E", "F", "G"],
            skey: "",
            degree: ["#", "♭", ""],
            scale: "",
            scales: [
                "Major",
                "Major Bepop",
                "Major Pentatonic",
                "Minor Natural",
                "Minor Harmonic",
                "Minor Hungarian",
                "Minor Melodic",
                "Minor Neapolitan",
                "Minor Pentatonic",
                "Arabic",
                "Diminished",
                "Dominant Bepop",
                "Dorian",
                "Enigmatic",
                "Japanese Insen",
                "Locrian",
                "Lydian",
                "Mixolydian",
                "Phrygian",
                "Whole Tone",
            ],
            files: [],
            Tags: [],
            tag: "",
            ReleaseDate: new Date().toISOString(),
            restrictions: {},
            moods: [
                "Happy",
                "Epic",
                "Dreamy",
                "Euphoric",
                "Laid Back",
                "Quirky",
                "Relaxing",
                "Suspense",
                "Mysterious",
                "Sentimental",
                "Sad",
            ],
            ext: ["png", "jpg"],
            ft: "",
            Image: "",
            Wav: "",
            Stems: "",
            Genre: "",
            SubGenre: "",
            Mood: "",
            Key: "",
            BPM: null,
            fid: "",
            FreeDownload: "",
            Type: "Beat",
            Draft: false,
            loading: false,
            ready: false,
            saving: false,
            total_amount_per_period: {
                "from": "beats",
                "show": [
                    "sum(price) as total_amount"
                ],

                "over": "DAY",
                "where": "status = 'COMPLETED' and slug='mantra'"
            },
            total_views_per_period: {
                "from": "beats",
                "show": [
                    "sum(views) as total_views"
                ],

                "over": "DAY",
                "where": "slug='fife'"
            },
            total_people_per_period: {
                "from": "beats",
                "show": [
                    "count(distinct people) as total_people"
                ],

                "over": "DAY",
                "where": "slug='fife'"
            }
        };
    },
    created() {},
    computed: {
        uppy: function () {
            return new Uppy(this.restrictions)
                .use(ImageEditor, {
                    quality: 0.8,
                })
                .use(XHRUpload, {
                    headers: {
                        Authorization: "Bearer " + this.$store.state.user.token,
                    },
                    limit: 1,
                    endpoint: this.$baseurl + "/storage",
                    formData: true,
                    fieldName: "file",
                })
                .on("upload-success", (file, response) => {
                    const httpBody = response.body; // extracted response data

                    this.files.push(httpBody);
                    this.setFiles(this.files);
                    this.selectf(httpBody);
                    // do something with file and response
                })
                .on("thumbnail:generated", (file, preview) => {
                    const img = new Image();

                    img.src = preview;
                    img.onload = () => {
                        const aspect_ratio = img.width / img.height;

                        if (aspect_ratio != 1) {
                            this.uppy.removeFile(file.id);
                            this.uppy.info(
                                "the image must be a square of at least 500x500px."
                            );
                        }
                    };
                });
        },
        ttag() {
            var x = [];
            this.Tags.forEach((el) => {
                x.push(el.text);
            });
            return x;
        },
        filesf() {
            var filesx = [];
            let sub = "";
            for (let x in this.files) {
                sub = this.files[x].original_name.substr(
                    this.files[x].original_name.lastIndexOf(".") + 1
                );

                if (this.ext.indexOf(sub) >= 0) {
                    filesx.push(this.files[x]);
                }
            }
            return filesx;
        },
    },
    mounted() {
        this.ready = false;

        var baseURI = this.$baseurl + "/storage";
        var config = {
            Authorization: "Bearer " + this.$store.getters.getUser.token,
        };
        this.$http
            .get(baseURI, {
                headers: config,
            })
            .then((result) => {
                if (result.data.message === "invalid token") {
                    this.$store.dispatch("signOut");
                } else {
                    this.setFiles(result.data);
                    this.files = result.data;
                    //this.keys = Object.keys(this.res[0]);
                    this.get_beat();

                }
            });

        //$('#file_modal').modal('show')
    },

    methods: {
        ...mapMutations(["setFiles", "setBeats"]),
        cancel() {
            this.$router.push("/d/beats");
        },
        remove_image() {
            this.Image = "";
            this.dimage = {};
        },
        remove_wav() {
            this.Wav = "";
            this.dwav = {};
        },
        remove_zip() {
            this.Zip = "";
            this.Stems = "";
            this.dzip = {};
        },
        get_beat() {
            if (this.$route.params.id) {

                var baseURI = this.$baseurl + "/beats/" + this.$route.params.id;
                var config = {
                    Authorization: "Bearer " + this.$store.getters.getUser.token,
                };
                if (this.$store.state.beats == null) {
                    this.$http
                        .get(baseURI, {
                            headers: config,
                        })
                        .then((result) => {
                            if (result.data.message === "invalid token") {} else {}
                            console.log(result.data);
                            this.populate(result.data[0]);
                            if (result.data[0]) {
                                //this.$nextTick(() => {
                                    this.total_amount_per_period = {
                                        "from": "beats",
                                        "show": [
                                            "sum(price) as total_amount"
                                        ],

                                        "over": "DAY",
                                        "where": "status = 'COMPLETED' and slug='" + this.$route.params.id + "'"
                                    }
                                    this.total_views_per_period = {
                                        "from": "beats",
                                        "show": [
                                            "sum(views) as total_views"
                                        ],

                                        "over": "DAY",
                                        "where": "slug='" + this.$route.params.id + "'"
                                    }
                                    this.total_people_per_period = {
                                        "from": "beats",
                                        "show": [
                                            "count(distinct people) as total_people"
                                        ],

                                        "over": "DAY",
                                        "where": "slug='" + this.$route.params.id + "'"
                                    }
                                //});

                            }

                            //this.keys = Object.keys(this.res[0]);
                        });
                } else {
                    var rr = this.$store.state.beats.filter((obj) => {
                        return obj.slug === this.$route.params.id;
                    });

                    this.populate(rr[0]);
                    this.ready = true;
                }
            } else {
                this.ready = true;
            }
        },
        switcht() {
            $('#pills-tab a[href="#pills-upload"]').tab("show");
        },
        verify() {
            console.log("verify");
            if (this.BPM === "") {
                this.BPM = null;
            }
            var send = {};
            this.loading = true;
            this.validationErrors = [];
            if (this.Name === "") {
                this.validationErrors.push({
                    message: "Beat Name can't be empty",
                });
                this.loading = false;
            }
            if (this.Wav === "") {
                this.validationErrors.push({
                    message: "Please Upload a Wav or Mp3 file",
                });
                this.loading = false;
            }
            if (this.Genre === "") {
                this.validationErrors.push({
                    message: "Genre can't be empty",
                });
                this.loading = false;
            }
            if (this.SubGenre === "") {
                this.validationErrors.push({
                    message: "SubGenre can't be empty",
                });
                this.loading = false;
            }

            if (this.BPM !== null && (this.BPM < 0 || this.BPM > 1000)) {
                this.validationErrors.push({
                    message: "BPM must be between 0 and 1000",
                });
                this.loading = false;
            }
            if (this.scale !== "" && this.skey === "") {
                this.validationErrors.push({
                    message: "Scale must have a key",
                });
                this.loading = false;
            }

            if (!this.validationErrors[0]) {
                this.saving = true;
                this.Key = this.skey + " " + this.scale;
                var tagx = [];
                this.Tags.forEach((el) => {
                    tagx.push(el.text);
                });

                send = {
                    id: this.ID || "",
                    name: this.Name,
                    key: this.Key || "",
                    user_id: this.UserId || "",
                    release_date: this.ReleaseDate,
                    description: this.Description || "",
                    tags: tagx,
                    image: this.Image,
                    wav: this.Wav,
                    stems: this.Stems || "",
                    genre: this.Genre,
                    sub_genre: this.SubGenre,
                    mood: this.Mood || "",
                    bpm: parseInt(this.BPM) || 0,
                    free_download: this.FreeDownload || "",
                    draft: this.Draft || false,
                    type: this.Type || "",
                };
                console.log("send");
                console.log(send);
                var baseURI,
                    op,
                    msg = "";
                baseURI = this.$baseurl + "/beats";

                if (this.$route.params.id) {
                    op = "update";
                    msg = "beat successfully updated";
                } else {
                    op = "upload";
                    msg = "beat uploaded successfully ";
                }

                var config = {
                    Authorization: "Bearer " + this.$store.getters.getUser.token,
                };

                if (op == "upload") {
                    console.log("upload");
                    this.$http
                        .post(baseURI, send, {
                            headers: config,
                        })
                        .then((result) => {
                            this.process(result, msg);
                            //this.keys = Object.keys(this.res[0]);
                        });
                } else if (op == "update") {
                    console.log("update");
                    this.$http
                        .put(baseURI, send, {
                            headers: config,
                        })
                        .then((result) => {
                            this.process(result, msg);
                            //this.keys = Object.keys(this.res[0]);
                        });
                }
            } else {
                Snackbar.show({
                    text: this.validationErrors[0].message,
                    customClass: "snack-danger",
                    actionText: "DISMISS",
                });
            }
        },
        process(result, msg) {
            if (result.data.error) {
                this.validationErrors.push({
                    message: result.data.message,
                });
                Snackbar.show({
                    text: result.data.message,
                    customClass: "snack-danger",
                    actionText: "DISMISS",
                });
                this.saving = false;
            } else {
                /*if (this.$route.params.id) {
                            var ind = this.$store.state.beats
                                .map((e) => e.id)
                                .indexOf(result.data.id);
                            var bts = this.$store.state.beats;
                            bts[ind] = result.data;
                            this.setBeats(bts);
                        } else {
                            var bts = this.$store.state.beats;
                            bts.push(result.data);
                            this.setBeats(bts);
                        }*/

                Snackbar.show({
                    text: msg,
                    customClass: "snack-success",
                    actionText: "OK",
                });
                this.saving = false;
                this.$router.push("/d/beats");
            }
        },
        populate(rs) {
            this.ID = rs["id"];
            this.Name = rs["name"];
            this.skey = rs["key"].split(" ")[0];
            this.UserId = rs["user_id"];
            if (rs["key"].split(" ")[2]) {
                this.scale = rs["key"].split(" ")[1] + " " + rs["key"].split(" ")[2];
            } else {
                this.scale = rs["key"].split(" ")[1];
            }
            this.ReleaseDate = rs["release_date"];
            this.Description = rs["description"];
            this.Draft = rs["draft"];
            this.Genre = rs["genre"];
            this.SubGenre = rs["sub_genre"];
            this.Mood = rs["mood"];
            this.FreeDownload = rs["free_download"];
            this.BPM = rs["bpm"];
            this.Type = rs["type"];
            this.Image = rs["image"];
            this.dimage =
                this.files.filter((obj) => {
                    return obj.id === this.Image;
                })[0] || {};
            this.Wav = rs["wav"];
            this.dwav =
                this.files.filter((obj) => {
                    return obj.id === this.Wav;
                })[0] || {};
            this.Stems = rs["stems"];
            this.dzip =
                this.files.filter((obj) => {
                    return obj.id === this.Stems;
                })[0] || {};
            var tagx = [];
            if (rs["tags"]) {
                rs["tags"].forEach((el) => {
                    tagx.push({
                        text: el,
                    });
                });
            }
            this.Tags = tagx;
            this.ready = true;
        },
        truncate(str, n) {
            return str.length > n ? str.substr(0, n - 1) + "..." : str;
        },
        selectf(file) {
            if (this.ft == "i") {
                this.dimage = file;
                this.Image = file.id;
            }
            if (this.ft == "w") {
                this.dwav = file;
                this.Wav = file.id;
            }
            if (this.ft == "z") {
                this.dzip = file;
                this.Stems = file.id;
            }
            $("#file_modal").modal("hide");
        },
        pick_filei() {
            this.ft = "i";
            this.fid = this.Image;
            this.ext = ["jpg", "jpeg", "png"];
            this.restrictions = {
                restrictions: {
                    maxFileSize: 10000000,
                    maxNumberOfFiles: 1,
                    allowedFileTypes: [".jpg", ".jpeg", ".png"],
                },
            };
            $("#file_modal").modal("show");
        },
        pick_filew() {
            this.ft = "w";
            this.fid = this.Wav;
            this.ext = ["wav", "mp3"];
            this.restrictions = {
                restrictions: {
                    maxFileSize: 120000000,
                    maxNumberOfFiles: 1,
                    allowedFileTypes: [".wav", ".mp3"],
                },
            };
            $("#file_modal").modal("show");
        },
        pick_filez() {
            this.ft = "z";
            this.fid = this.Stems;
            this.ext = ["zip", "rar"];
            this.restrictions = {
                restrictions: {
                    maxFileSize: 2000000000,
                    maxNumberOfFiles: 1,
                    allowedFileTypes: [".zip", ".rar"],
                },
            };
            $("#file_modal").modal("show");
        },
        fileimg(mime) {
            if (mime == "pdf") {
                return "ci-file_pdf";
            } else if (mime == "zip" || mime == "rar" || mime == "tar") {
                return "ci-file_archive";
            } else {
                return "ci-file_blank_fill";
            }
        },
    },
    beforeDestroy() {
        this.uppy.close();
    },
};
</script>

<style>
</style>
